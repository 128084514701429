declare module '*.scss';
import UserNotFoundImage from './assets/images/user-not-found.jpg';
import { ApiResponse, ErrorDetail } from './interfaces';

export const enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export type AuthResponse = {
  token: string;
  user: {
    created_at: string;
    email: string;
    company_id: number;
    id: number;
    role: UserRole;
    isAgree: boolean;
    updated_at: string;
  };
  userDetails: {
    forename: string;
    surname: string;
  };
};

export type userStatusResponse = {
  user_id: number;
  is_registered: boolean;
  is_joined: boolean;
  is_first_short_star_completed: boolean;
};

export type updateUserStatusRequest = {
  is_registered?: boolean;
  is_joined?: boolean;
  is_first_short_star_completed?: boolean;
};

export type ImportResults = {
  failed: ImportedRowType[];
  successful: ImportedRowType[];
};

export type ImportedRowType = {
  email: string;
  forename: string;
  surname: string;
  reason: string;
};

export type TwitterCardType = 'summary' | 'summary_large_image' | 'app' | 'player';

export type Coordinate = {
  x: number;
  y: number;
};

export type CoordinatePosition = {
  p1: Coordinate;
  p2: Coordinate;
  p3: Coordinate;
  p4: Coordinate;
};

export type RhombusCoordinates = {
  p1: Coordinate;
  p2: Coordinate;
  p3: Coordinate;
  p4: Coordinate;
  position: RhombusPosition;
  inner: CoordinatePosition;
};

export type StarConfig = {
  name: string;
  color: string;
  value: number[];
};

export type LocationItemType = {
  name: string;
  id: number;
};

export type SelectableOptionType = {
  label: string;
  value: any;
};

export interface UserFieldValues {
  email: string;
  surname: string;
  forename: string;
  seniority: Seniority;
  sex: Genders;
  payLevel: number;
  jobType: SelectableOptionType | null;
  jobTitle: SelectableOptionType | null;
  team: SelectableOptionType | null;
  location: SelectableOptionType | null;
  dob: string | null;
  joined: string | null;
  peopleLead: boolean;
  isAdmin: boolean;
}

export type JobTypeType = {
  name: string;
  id: number;
};

export type SeniorityType = {
  name: string;
  id: number;
};

export type JobTitleType = {
  name: string;
  id: number;
};

export type TeamItemType = {
  name: string;
  id: number;
};

export const enum RhombusPosition {
  Top = 'top',
  RightTop = 'rightTop',
  RightBottom = 'rightBottom',
  Bottom = 'bottom',
  LeftTop = 'leftTop',
  LeftBottom = 'leftBottom',
}

export type SelectedUser = User | undefined;

export interface User {
  row: number;
  id: number;
  email: string;
  role: string;
  company: string;
  surname: string;
  forename: string;
  jobType: string;
  jobTitle: string;
  team: string;
  seniority: string;
  sex: string;
  payLevel: number | string;
  location: string;
  dob: string;
  joined: string;
  peopleLead: string;
  speakupAlertEligibility: boolean | string;
}

type Seniority = 'non_specified' | 'junior' | 'mid_level' | 'senior' | 'c_level';

type Genders = 'male' | 'female' | 'non_binary' | 'non_specified';

type PeopleLead = '' | 'yes' | 'no';

export interface UsersFilterData {
  location: string;
  team: string;
  gender: Genders;
  seniority: 'string';
  jobType: 'string';
  jobTitle: 'string';
  payLevel: number;
  peopleLead: PeopleLead;
  speakupAlertEligibility?: '' | 'yes' | 'no';
}

export interface CompanyDetails {
  company_logo: string;
  company_name: string;
  company_url: string;
  id: number;
  sign_up_text: string;
  login_text: string;
  share_thoughts: boolean;
}

export interface CompanyConfigData {
  showInstantHelp: boolean;
  instantHelpDescription: string;
  showSpeakUp: boolean;
  welcomeMessageText: string | null;
  expertsEnabled: boolean;
}

export interface InstantHelpData {
  id: number;
  tag: string;
  sections: InstantHelpSection[];
}

export interface InstantHelpSection {
  id: number;
  instant_help_sections_id: number;
  order_id: number;
  title: string;
  description_text: string;
  instantHelpActions: InstantHelpAction[];
}

export interface InstantHelpAction {
  id: number;
  label: string;
  type: 'phone' | 'email' | 'url' | 'text';
  value: string;
  icon: string;
}

export interface HelpLimitData {
  id: number;
  question_id: number;
  limit: number;
}

export const enum LogTypes {
  INSTANT_HELP_DISPLAYED = 'starInstantHelpDisplayed',
  INSTANT_HELP_CLICKED = 'starInstantHelpClicked',
  SPEAKUP_DISPLAYED = 'starSpeakUpDisplayed',
  INSTANT_HELP_NAVIGATED = 'instantHelpNavigated',
  ACTION_ALERT_EMAIL_CLICKED = 'actionAlertEmailClicked',
  LEARNING_ITEM_CLICKED = 'learningItemClicked',
  INSTANT_HELP_NAVIGATED = 'instantHelpNavigated',
  AUDIO_PLAYED = 'audioPlayed',
  IMPROVE_PLAN_CREATED = 'improvePlanCreated',
  ACTION_ALERT_EMAIL_CLICKED = 'actionAlertEmailClicked',
  EXPERTS_PAGE_VISITED = 'expertsPageVisited',
  EXPERT_PROFILE_CLICKED = 'expertProfileClicked',
  EXPERT_BOOKING_EMAIL_SENT = 'expertsBookingEmailSent',
  EXPERT_SESSION_REMINDER = 'expertSessionReminder',
  EMPLOYER_DASHBOARD_USER_IMPORT = 'employerDashboardUserImport',
  EMPLOYER_DASHBOARD_USER_EXPORT = 'employerDashboardUserExport',
  SSO = 'sso',
  DIRECT_LINK = 'directLink',
  NOT_AGREED_TO_TERMS_EMAIL_SENT = 'notAgreedToTermsEmailSent',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN_EMAIL_SENT = 'termsAcceptedButNotCheckedInEmailSent',
  NO_DEEP_DIVE_TAKEN_EMAIL_SENT = 'noDeepDiveTakenEmailSent',
  NOT_AGREED_TO_TERMS_EMAIL_CLICKED = 'notAgreedToTermsEmailClicked',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN_EMAIL_CLICKED = 'termsAcceptedButNotCheckedInEmailClicked',
  NO_DEEP_DIVE_TAKEN_EMAIL_CLICKED = 'noDeepDiveTakenEmailClicked',
}

export interface SpeakUpResponseInterface {
  questionnaireId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: boolean;
  speakUpCreatedDate: string;
  speakUpUpdatedDate: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  responded: boolean;
  response?: {
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
    responseCreated: string;
    responderFirstName: string;
    responderLastName: string;
  };
  isSpeakup?: boolean;
}

export interface ThoughtResponseInterface {
  thought_response_id: number;
  thought_id: number;
  questionnaire_name: string;
  category_name: string;
  title: string;
  score: number;
  thoughts_response_text: string;
  created_at: string;
  updated_at: string;
  color: string;
  questionnaire_id: number;
  shareWith: string;
  thoughtsText: string;
  responded: boolean;
  isSpeakup?: boolean;
  response?: ResponsesForThoughtsInterface;
}

export interface ResponsesForThoughtsInterface {
  category_name: string;
  color: string;
  created_at: string;
  questionnaire_name: string;
  score: number;
  thought_id: number;
  thought_response_id: number;
  thoughts_response_text: string;
  title: string;
  updated_at: string;
}

export interface ThoughtDataInterface {
  questionnaireId: number;
  questionnaireName: string;
  categoryName: string;
  thoughtId: number;
  title: string;
  score: number;
  shareWith: string;
  thoughtsText: string;
  responded: boolean;
  color: string;
  createdAt: string;
  updatedAt: string;
  response?: {
    responseId: number;
    responseMessage: string;
    responseCreated: string;
  };
  responsesSeen?: boolean;
  isSpeakup?: boolean;
}

export interface SpeakUpDataInterface {
  questionnaireId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: false;
  speakUpCreatedDate: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  scores: {
    instantHelpTriggerId: number;
    assessment_id: number;
    questionId: number;
    questionText: string;
    score: number;
  }[];
  response?: {
    responderFirstName: string;
    responderLastName: string;
    responseCreated: string;
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
  };
}

export interface PaginationInterface {
  total?: number;
  lastPage?: number;
  prevPage?: number;
  nextPage?: number;
  perPage?: string;
  currentPage?: number;
  from?: number;
  to?: number;
}

export interface ShortStarQuestionsInterface {
  categoryId: number;
  generalHappinessQuestion: boolean;
  isActive: boolean;
  isShortQuestionnaire: boolean;
  questionId: number;
  questionInfo: string;
  questionText: string;
  questionnaireColorScheme: 'standard' | 'dark' | 'light';
  questionnaireId: number;
  specialWeighting: boolean;
  colorTheme: string;
  icon: string;
}

export type ShortStatContentTypes =
  | 'WELCOME_SECTION'
  | 'GENERAL_HAPPINESS_QUESTION_SECTION'
  | 'QUESTIONS_SECTION'
  | 'SCORE_DIFFERENCE_SECTION'
  | 'REFLECTION_SECTION'
  | 'FOCUS_SECTION'
  | 'IMPROVE_SECTION'
  | 'COMPLETE_SHORT_STAR';

export const shortStarContent = {
  WELCOME_SECTION: 'WELCOME_SECTION',
  GENERAL_HAPPINESS_QUESTION_SECTION: 'GENERAL_HAPPINESS_QUESTION_SECTION',
  QUESTIONS_SECTION: 'QUESTIONS_SECTION',
  SCORE_DIFFERENCE_SECTION: 'SCORE_DIFFERENCE_SECTION',
  REFLECTION_SECTION: 'REFLECTION_SECTION',
  FOCUS_SECTION: 'FOCUS_SECTION',
  IMPROVE_SECTION: 'IMPROVE_SECTION',
  COMPLETE_SHORT_STAR: 'COMPLETE_SHORT_STAR',
};

export interface UserImprovePlanActions {
  completeAt: string | null;
  createdAt: string | null;
  done: boolean | null;
  id: number;
  progress: number;
  question: QuestionInterface;
  questionId: number;
  updatedAt: string;
  userId: number;
  actionCount: number;
  improvePlansFocusAreas?: PlanFocusAreaInterface[];
}

export interface QuestionInterface {
  categoryId: number;
  color: string;
  createdAt: string;
  generalHappinessQuestion: boolean;
  icon: string;
  id: number;
  questionInfo: string;
  questionText: string;
  specialWeighting: boolean;
  updatedAt: string;
}

export interface ImprovePlanAction {
  improvePlanId: number;
  improvePlanActionId: number;
  improvePlanActionDone: boolean;
  actionTitle: string;
  actionDescription: string | null;
  improvePlanActionDueDate: string;
  improvePlanActionDueDays: number;
  improvePlanActionRemoved: boolean;
  improvePlanActionRemovedAt: string | null;
  improvePlanActionStartAt: string | null;
  improvePlanActionCompletedAt: string | null;
  improvePlanActionCreatedAt: string;
  improvePlanActionUpdatedAt: string;
  actionImage: string | null;
  actionId: number | null;
  actionType: string;
  actionURL: string | null;
  actionButton: string | null;
}

export interface ImprovePlan {
  id: number;
  userId: number;
  questionId: number;
  done: boolean;
  completeAt: string | null;
  progress: number;
  createdAt: string;
  updatedAt: string;
  improvePlanActions: ImprovePlanAction[];
}

export interface DeepDiveQuestionnairePlan {
  id: number;
  shortQuestionId: number;
  deepDiveId: number;
  createdAt: string;
  updatedAt: string;
  question: {
    id: number;
    categoryId: number;
    questionText: string;
    questionInfo: string;
    specialWeighting: boolean;
    generalHappinessQuestion: boolean;
    color: string;
    icon: string;
    createdAt: string;
    updatedAt: string;
    improvePlans?: DeepDiveQuestionnaireImprovePlan[];
  };
}

interface DeepDiveQuestionnaireImprovePlan {
  id: number;
  userId: number;
  improvePlansFocusAreas: ImprovePlanFocusAreas[];
  questionId: number;
  done: boolean;
  completeAt: string | null;
  progress: number;
  createdAt: string;
  updatedAt: string;
}

interface ImprovePlanFocusAreas {
  id: number;
  improvePlanId: number;
  questionId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface FocusAreaQuestionInterface {
  color?: string;
  hint?: string;
  id: number;
  isSelected: boolean;
  name: string;
  score?: number;
  scoreDifference?: number;
  shareWith?: string;
  specialWeighting?: boolean;
  thoughtsText?: string;
  improvePlanId?: number;
  questionId?: number;
  active?: boolean;
}

interface PlanFocusAreaInterface {
  id: number;
  improvePlanId: number;
  questionId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  question: {
    id: number;
    categoryId: number;
    questionText: string;
    questionInfo: string;
    specialWeighting: boolean;
    generalHappinessQuestion: boolean;
    color: string;
    icon: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const ImprovePlanTypes = {
  SHORT_STAR: 'SHORT_STAR',
  DEEP_DIVE: 'DEEP_DIVE',
  LEARNING_ARTICLE: 'LEARNING_ARTICLE',
};

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const isIOS = iOS();

export enum ExpertBookingTypes {
  VIDEO = 1,
  AUDIO = 2,
  IM = 4,
  FACE_TO_FACE = 8,
}

export enum SessionDurations {
  DURATION_25_MINS = 1,
  DURATION_50_MINS = 2,
  DURATION_80_MINS = 4,
}

export enum ExpertLogTypes {
  EXPERTS_PAGE_VISITED = 'expertsPageVisited',
  EXPERT_PROFILE_CLICKED = 'expertProfileClicked',
}

export const USER_NOT_FOUND_IMAGE = UserNotFoundImage;

export const castResponse = <T>(response: ApiResponse<any> | ApiResponse<ErrorDetail>) =>
  response.responseObject as unknown as T;

export const ReminderTypes = {
  NOT_AGREED_TO_TERMS: 'notAgreedToTerms',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN: 'termsAcceptedButNotCheckedIn',
  NO_DEEP_DIVE_TAKEN: 'noDeepDiveTaken',
};

export enum ExpertsBookingStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export const CURRENT_TIMEZONE_NAME = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum AvailabilityTimeNames {
  AVAILABILITY = 'Availability',
  EARLY_MORNING = 'Early Morning (05.00 - 09.00)',
  MORNING = 'Morning (09.00 - 13.00)',
  AFTERNOON = 'Afternoon (13.00 - 17.00)',
  EVENING = 'Evening (17.00 - 21.00)',
  LATE_EVENING = 'Late Evening (21.00 - 01.00)',
}

export const EXPERTS_TIME_FILTERS = [
  {
    id: -9999,
    name: AvailabilityTimeNames.AVAILABILITY,
    value: null,
  },
  {
    id: 1,
    name: AvailabilityTimeNames.EARLY_MORNING,
    value: {
      start: '05:00:00',
      end: '09:00:00',
    },
  },
  {
    id: 2,
    name: AvailabilityTimeNames.MORNING,
    value: {
      start: '09:00:00',
      end: '13:00:00',
    },
  },
  {
    id: 3,
    name: AvailabilityTimeNames.AFTERNOON,
    value: {
      start: '13:00:00',
      end: '17:00:00',
    },
  },
  {
    id: 4,
    name: AvailabilityTimeNames.EVENING,
    value: {
      start: '17:00:00',
      end: '21:00:00',
    },
  },
  {
    id: 5,
    name: AvailabilityTimeNames.LATE_EVENING,
    value: {
      start: '21:00:00',
      end: '1:00:00',
    },
  },
];

export const HourBuffCategories = {
  1: '0-3',
  2: '3-6',
  3: '6-9',
  4: '9-12',
  5: '12-15',
  6: '15-18',
  7: '18-21',
  8: '21-24',
  9: '24-28',
};
