import { AppDispatch } from '@hooks/useAppDispatch';
import { companyConfigActions } from 'store/Slices/CompanyConfigSlice';
import { getCompanyConfig } from '@api/companyConfig';

export const getCompanyConfigurations = () => async (dispatch: AppDispatch) => {
  dispatch(companyConfigActions.setLoading({ loading: true }));

  try {
    const data = await getCompanyConfig();
    if (data) {
      dispatch(
        companyConfigActions.updateConfigurationData({
          showInstantHelp: data.showInstantHelp,
          instantHelpDescription: data.instantHelpDescription,
          showSpeakUp: data.showSpeakUp,
          welcomeMessageText:
            data.welcomeMessageText &&
            data.welcomeMessageText.length > 0 &&
            data.welcomeMessageText !== 'null'
              ? data.welcomeMessageText
              : null,
          expertsEnabled: data.expertsEnabled,
        }),
      );
    } else {
      dispatch(companyConfigActions.setError({ error: 'Loading company configurations failed' }));
    }
  } catch (error) {
    dispatch(companyConfigActions.setError({ error: 'Loading company configurations failed' }));
  }
};
