import { createImprovePlan, getDeepDives } from '@api/actionPlan';
import { getAssessment, getScores } from '@api/assessment';
import { getQuestionnairesNames } from '@api/questionnaires';
import DefaultButton from '@components/Atoms/DefaultButton';
import RoundedSquareButton from '@components/Molecules/RoundedSquareButton';
import { SET_CURRENT_TAB, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CategoryScores } from '@interfaces/index';
import { Grid } from '@mui/material';
import { ImprovePlanTypes, ShortStatContentTypes } from 'custom.d';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import styles from './index.module.scss';

const FocusSection = ({ handleStep }: FocusSectionProps) => {
  const reduxDispatch = useAppDispatch();
  const [state, dispatch] = useGlobalState();
  const { toggleSubNav } = useContext(SecondaryNavigationContext);
  const history = useHistory();
  const wizardData = useAppSelector((st: RootState) => st.shortStarWizard);
  const improvePlans = wizardData.actionPlans;
  const [selectedImprove, setSelectedImprove] = useState<QuestionsScores | null>(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<{
    questionId: number;
    questionText: string;
    colorTheme: string;
    deepDiveId: number;
    latestAssessmentScore: number;
    icon: string;
    deepDiveName: string;
  } | null>(null);

  const [clickedButtonId, setClickedButtonId] = useState<number | null>(null);
  const [categoryScores, setCategoryScores] = useState<CategoryScores[] | any>();
  const { isDesktop, isMobile } = useWindowDimensions();

  useEffect(() => {
    if (selectedQuestionnaire) {
      getAssessmentData();
    }
  }, [selectedQuestionnaire]);

  const getAssessmentData = async () => {
    getAssessment(1, isDesktop ? 10 : 5, selectedQuestionnaire?.deepDiveId, 0)
      .then((data) => {
        const { catScores } = data;

        if (catScores && catScores?.length > 0) {
          setCategoryScores(
            [...catScores].sort((a: CategoryScores, b: CategoryScores) => b.score - a.score),
          );
        } else {
          setCategoryScores([]);
        }
      })
      .catch((err) => {
        if (err) {
          setCategoryScores([]);
        }
      });
  };

  useEffect(() => {
    if (selectedImprove) {
      getDives();
    }
  }, [selectedImprove]);

  const getDives = async () => {
    const res = await getDeepDives();
    if (res?.length > 0) {
      const questionnaire = res.find(
        (q) => Number(q.questionId) === Number(selectedImprove?.questionId),
      );

      setSelectedQuestionnaire(questionnaire || null);
    }
  };

  useEffect(() => {
    if (!wizardData.isImproveAreaAlreadySelected && wizardData?.actionPlans?.length === 0) {
      findLowestScoreQuestion();
    } else {
      setSelectedImprove(null);
      setClickedButtonId(null);
    }
  }, [wizardData.isImproveAreaAlreadySelected, wizardData?.actionPlans]);

  const findLowestScoreQuestion = () => {
    if (wizardData.questionsScores.length === 0) {
      setSelectedImprove(null);
      setClickedButtonId(null);

      return;
    }

    const sortedQuestions = [...wizardData.questionsScores].sort((a, b) => a.score - b.score);

    const areAllScoresEqual = sortedQuestions.every(
      (question, index, array) => question.score === array[0].score,
    );

    if (areAllScoresEqual) {
      setSelectedImprove(null);
      setClickedButtonId(null);
    } else {
      setSelectedImprove(sortedQuestions[0]);
      setClickedButtonId(sortedQuestions[0]?.questionId);
    }
  };

  const handleButtonClick = (data: QuestionsScores) => {
    if (clickedButtonId === data.questionId) {
      setClickedButtonId(null);
      setSelectedImprove(null);
    } else {
      setClickedButtonId(data.questionId);
      setSelectedImprove(data);
    }
  };

  const selectedQuestionId = selectedImprove?.questionId;
  const isQuestionIdIncluded =
    selectedQuestionId && selectedQuestionId > 0
      ? improvePlans.some((plan) => plan.questionId === selectedQuestionId)
      : false;

  const handleContinue = async () => {
    if (!selectedImprove) return;

    const { questionId } = selectedImprove;
    const { deepDiveId, deepDiveName } = selectedQuestionnaire || {};
    const improvePlanUrl = `/improve-plans/?improveAreaId=null&improvePlan=null&questionId=${questionId}`;

    await reduxDispatch(shortStarWizardActions.addImprovementArea(selectedImprove));
    await handleStep('IMPROVE_SECTION');

    if (questionId) {
      await createImprovePlan(ImprovePlanTypes.SHORT_STAR, questionId, null, null);
    }

    if (!wizardData.isImproveAreaAlreadySelected) {
      await reduxDispatch(shortStarWizardActions.updateImrpoveSectionSelected(true));
    }

    if (deepDiveId && deepDiveName) {
      await dispatch({
        type: SET_CURRENT_TAB,
        payload: {
          id: deepDiveId,
          name: deepDiveName,
        },
      });

      await toggleSubNav({ id: deepDiveId, name: deepDiveName });

      if (isQuestionIdIncluded) {
        history.push(improvePlanUrl);
      } else {
        history.push(categoryScores.length > 0 ? '/stars' : '/create-a-star');
      }
    } else {
      history.push(improvePlanUrl);
    }

    dispatch(shortStarWizardActions.resetWizard());
  };

  return (
    <div className={styles.focusSelectionSection}>
      <div>
        {selectedImprove ? (
          <div className={styles.headingContainer}>
            <h4 className={styles.letsFocusText}>
              Let’s focus on your <span>{selectedImprove.questionText} </span>{' '}
              {selectedImprove.questionText !== 'Mood' && 'wellbeing'}, or feel free to select
              another area to focus
            </h4>
          </div>
        ) : (
          <div className={styles.headingContainer}>
            <h4 className={styles.letsFocusText}>Select one of the areas to focus</h4>
          </div>
        )}
      </div>

      <Grid container spacing={2} className={styles.focusSelectionGrid}>
        {wizardData?.questionsScores?.map(
          (button: {
            questionId: number;
            colorTheme: string;
            score: number;
            questionText: string;
            icon: string;
          }) => (
            <Grid item xs={6} key={button.questionId}>
              <RoundedSquareButton
                id={button.questionId}
                colorTheme={button.colorTheme}
                selected={clickedButtonId === button.questionId}
                onClick={() => handleButtonClick(button)}
                score={button.score}
                title={button.questionText}
                icon={button.icon}
              />
            </Grid>
          ),
        )}
      </Grid>

      <div className={styles.continueBtnContainer}>
        <DefaultButton
          variant="primary"
          type="button"
          className={styles.continueBtnPrimary}
          onClick={handleContinue}
          disabled={selectedImprove === null}
        >
          Continue
        </DefaultButton>
      </div>
    </div>
  );
};

FocusSection.defaultProps = {};

interface FocusSectionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
}

interface QuestionsScores {
  questionId: number;
  questionInfo?: string;
  questionText: string;
  colorTheme: string;
  generalHappinessQuestion?: boolean;
  icon: string;
  questionnaireId?: number;
  score: number;
}

export default FocusSection;
