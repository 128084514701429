import { createImprovePlan } from '@api/actionPlan';
import Button from '@components/Button';
import CategoryScoreCard from '@components/CategoryScoreCard';
import Checkbox from '@components/Checkbox';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { CreateStarQuestionInterface } from '@interfaces/index';
import classNames from 'classnames';
import { FocusAreaQuestionInterface, ImprovePlanTypes } from 'custom.d';
import React, { useContext, useEffect, useState } from 'react';
import { RootState } from 'store';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const AddPlanFocusArea: React.FunctionComponent<FocusAreaProps> = (props) => {
  const {
    focusAreas,
    onClose,
    sendFocusData,
    setFocusAreas,
    isActionPage,
    maxSelectCount = 3,
  } = props;
  const [questionnaireIds] = useState<number[]>([]);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [newFocusArea, setNewFocusAreas] = useState<any>([]);
  const reduxDispatch = useAppDispatch();
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);

  useEffect(() => {
    const updatedFocusAreas = focusAreas.map((area) => {
      const updatedArea = { ...area };
      reduxStarScores.selectedFocusAreas.forEach((selectedArea) => {
        if (updatedArea.id === selectedArea.id && selectedArea.isSelected) {
          updatedArea.isSelected = true;
        }
      });

      return updatedArea;
    });

    setNewFocusAreas(updatedFocusAreas);
  }, [focusAreas]);

  const handleAddAction = async () => {
    // eslint-disable-next-line no-alert
    const newData = newFocusArea.filter((i: any) => i.isSelected !== false);
    await reduxDispatch(starScoresSliceActions.setFocusAreas(newData));
    const ids = newData.map((k: any) => k.id);

    const res = await createImprovePlan(
      ImprovePlanTypes.DEEP_DIVE,
      null,
      activeSubNav.id,
      ids || [],
    );

    if (res) {
      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    setFocusAreas(newFocusArea);

    sendFocusData(newData, ids);
    onClose();
  };

  const handleChange = (focus: any, checked: any) => {
    const selectedFcousAreas = newFocusArea.filter((k: any) => k.isSelected);
    selectedFcousAreas.push(newFocusArea[0]);

    setNewFocusAreas((prev: any) =>
      prev.map((l: any) => ({
        ...l,
        isSelected: l.id === focus.id ? false : l.isSelected,
      })),
    );

    if (selectedFcousAreas.length <= maxSelectCount) {
      setNewFocusAreas((prev: any) =>
        prev.map((l: any) => ({
          ...l,
          isSelected: l.id === focus.id ? checked : l.isSelected,
        })),
      );
    }

    const selectedQuestions = newFocusArea.filter(
      (dt: FocusAreaQuestionInterface) => dt?.isSelected,
    );
    reduxDispatch(starScoresSliceActions.setFocusAreas(selectedQuestions));
  };

  return (
    <div className={classNames(styles.hsAddFocusArea)}>
      <div>
        {newFocusArea.map((focusArea: any) => (
          <div key={focusArea.id} className={styles.hsAddFocusAreaContainer}>
            <CategoryScoreCard
              category={focusArea.name}
              score={focusArea.score}
              color={focusArea.color}
            />
            <div className={styles.hsCheckboxContainer}>
              <Checkbox
                list={questionnaireIds.length}
                checked
                id={`fcs_area_${focusArea.id}`}
                text="Select"
                color={focusArea.color}
                isSelected={focusArea.isSelected}
                onChange={(e) => handleChange(focusArea, e.target.checked)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.hsButtonContainer}>
        <Button isButton type="primary" onClick={handleAddAction}>
          Add Focus Area
        </Button>
      </div>
    </div>
  );
};

AddPlanFocusArea.defaultProps = {
  isActionPage: false,
  maxSelectCount: 3,
};

interface FocusAreaProps {
  focusAreas: CreateStarQuestionInterface[];
  onClose: () => void;
  sendFocusData: (data: any, questionnaireIds: any) => void;
  setFocusAreas: (prev: any) => void;
  isActionPage?: boolean;
  maxSelectCount?: number;
}

export default AddPlanFocusArea;
