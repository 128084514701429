import { getUserImprovePlansByDeepDive } from '@api/actionPlan';
import { saveAssessment } from '@api/assessment';
import { getCompanyDetailsById } from '@api/auth/company';
import { getInstantHelpLimits, logInstantHelpStarDetails } from '@api/instantHelp';
import DeepDiveWizardFooter from '@components/DeepDiveQuestionContainer/Components/DeepDiveFooter';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';

import { getIpAddress } from '@api/auth/login';
import { ColorPalette } from '@enums/Colors';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarCategoryInterface, CreateStarQuestionInterface } from '@interfaces/index';
import HelpAndSpeakup from '@pages/EarlyWarning/HelpAndSpeakup';
import { HelpLimitData, ImprovePlanFocusAreas, LogTypes, isIOS } from 'custom.d';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RootState } from 'store';
import { updateHelpAvailability } from 'store/Actions/HelpAndSpeakupAction';
import {
  DefaultDeepDiveValues,
  Question,
  deepDiveWizardActions,
} from 'store/Slices/DeepDiveWizardSlice';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import StarFirstStep from '../Steps/1-StarFirstStep';
import DeepDiveScoringStep from '../Steps/2-DeepDiveQuestionSteps';
import ScoreSubmitStep from '../Steps/3-ScoreSubmitStep';
import SelectingFocusAreaStep from '../Steps/4-SelectingFocusAreaStep';
import ActionsForPlanStep from '../Steps/5-ActionsForPlanStep';
import styles from './index.module.scss';

const DeepDiveQuestionContainerMobile: React.FC = () => {
  const reduxDispatch = useAppDispatch();
  const isMobileApp = useGoNative();
  const { isMobile } = useWindowDimensions();

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [sliderValues, setSliderValues] = useState<SliderValues>({});
  const [modifiedScores, setModifiedScores] = useState<Set<number>>(new Set());
  const deepDiveWizard = useAppSelector((state: RootState) => state.deepDiveWizard);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const [questionLimits, setQuestionLimits] = useState<HelpLimitData[]>([]);
  const [failsQuestions, setFailsQuestions] = useState<FailsQuestionsInterface[]>([]);
  const [idsQuestionaires, setIdsquestionaires] = useState<any>([]);
  const helpConfig = useAppSelector((state: RootState) => state.helpConfig.data);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);

  const { isHelpAvailable } = helpConfig;
  const { showSpeakUp } = companyConfig;

  const { wizardStep, wizardSubStep, categoriesWithQuestions, parsedCategory, assessmentDetails } =
    deepDiveWizard;

  const isNextButtonEnabled = modifiedScores.has(activeCategoryIndex);

  const [assessmentDataForHelp, setAssessmentDataForHelp] = useState<AssessmentData>({
    assessmentId: 0,
    questionnaireId: 0,
  });

  const [previewInstantHelp, setPreviewInstantHelp] = useState<boolean>(false);
  const [customButtonName, setCustomButtonName] = useState<string>('');

  useEffect(() => {
    getInstantHelpLimits().then((response) => {
      setQuestionLimits(response);
    });
  }, []);

  useEffect(() => {
    if (
      isHelpAvailable &&
      assessmentDetails &&
      assessmentDetails?.assessment_id > 0 &&
      wizardSubStep === 'INSTANT_HELP'
    ) {
      logStarInstantHelp();
      setAssessmentDataForHelp({
        assessmentId: assessmentDetails.assessment_id,
        questionnaireId: assessmentDetails.questionnaire_id,
      });
      setFailsQuestions([]);
    }
  }, [isHelpAvailable, wizardSubStep]);

  useEffect(() => {
    const ids = deepDiveWizard.selectedAndParsedUpdatedCategories.flatMap((category) =>
      category.questions.filter((i) => i.isSelected).flatMap((o) => o.id),
    );
    setIdsquestionaires(ids);
  }, [deepDiveWizard.selectedAndParsedUpdatedCategories]);

  useEffect(() => {
    const defaultSliderValues: SliderValues = {};

    deepDiveWizard.categoriesWithQuestions.forEach((category) => {
      category.questions.forEach((question) => {
        defaultSliderValues[question.questionId] = {
          questionId: question.questionId,
          questionText: question.name,
          score: DefaultDeepDiveValues.SCORE,
        };
      });
    });

    setSliderValues(defaultSliderValues);
  }, []);

  useEffect(() => {
    reduxDispatch(deepDiveWizardActions.storeDeepDiveQuestionsSliderValues(sliderValues));
  }, [sliderValues, reduxDispatch]);

  useEffect(() => {
    const getCurrentCompanyDetails = async () => {
      try {
        const comDetails = await getCompanyDetails();
        if (comDetails) {
          const company = await getCompanyDetailsById(String(comDetails.id));
          if (company) {
            reduxDispatch(
              deepDiveWizardActions.updateThoughtSharing(
                company.share_thoughts || comDetails.share_thoughts,
              ),
            );
          }
        }
      } catch (error) {
        reduxDispatch(deepDiveWizardActions.updateThoughtSharing(false));
      }
    };

    getCurrentCompanyDetails();
  }, [reduxDispatch]);

  useEffect(() => {
    getActiveImprovePlans();
  }, []);

  const getActiveImprovePlans = async () => {
    type StepType =
      | 'NO_ACTIVE_PLANS'
      | 'HAS_PLANS_NO_FOCUS_AREAS'
      | 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
    const uPlans: any = await getUserImprovePlansByDeepDive(activeSubNav.id, true);

    reduxDispatch(starScoresSliceActions.addDeepDiveQuestionWithPlan(uPlans));

    const modUplan = uPlans[0]?.question?.improvePlans;

    let step: StepType = 'NO_ACTIVE_PLANS';
    let focusAreas: ImprovePlanFocusAreas[] = [];

    if (modUplan) {
      if (modUplan?.length === 0) {
        step = 'NO_ACTIVE_PLANS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)
          ?.length === 0
      ) {
        step = 'HAS_PLANS_NO_FOCUS_AREAS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)?.length >
          0
      ) {
        step = 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
        focusAreas = modUplan[0]?.improvePlansFocusAreas;
      }
    }

    reduxDispatch(starScoresSliceActions.updateCurrentStep({ step, focusAreas }));
  };

  const handleSliderChange = useCallback(
    (event: Event, newValue: number, itemId: number, questionText: string) => {
      setSliderValues((prevValues) => {
        const newValues = {
          ...prevValues,
          [itemId]: {
            questionId: itemId,
            questionText,
            score: newValue,
          },
        };

        setModifiedScores((prevModifiedScores) => {
          const newModifiedScores = new Set(prevModifiedScores);
          newModifiedScores.add(activeCategoryIndex);

          return newModifiedScores;
        });

        return newValues;
      });
    },
    [activeCategoryIndex],
  );

  const handleNextCategory = async () => {
    checkScoreFailsLimit();

    if (activeCategoryIndex < deepDiveWizard.categoriesWithQuestions.length - 1) {
      await setActiveCategoryIndex((prevIndex) => prevIndex + 1);

      await reduxDispatch(deepDiveWizardActions.updateWizardStep('SCORE'));
      await reduxDispatch(deepDiveWizardActions.updateWizardSubStep('QUESTIONS'));
    }

    if (activeCategoryIndex === deepDiveWizard.categoriesWithQuestions.length - 1) {
      reduxDispatch(deepDiveWizardActions.parseCategoryDetails(parsedCategoryValues));

      if (wizardStep === 'SCORE' && wizardSubStep === 'QUESTIONS') {
        const updatedParsedCategoryValues = parsedCategoryValues.map((category) => ({
          ...category,
          questions: category.questions.map((question) => {
            const thoughtDetail = deepDiveWizard.thoughtsDetails.find(
              (td) => td.questionId === question.id,
            );

            return {
              ...question,
              shareWith: thoughtDetail ? thoughtDetail.shareWith : '',
              thoughtsText: thoughtDetail ? thoughtDetail.thoughts || '' : '',
            };
          }),
        }));

        const assessmentResponse = await saveAssessment(
          updatedParsedCategoryValues,
          activeSubNav.id,
        );

        if (assessmentResponse.success) {
          reduxDispatch(deepDiveWizardActions.updateAssessmentDetails(assessmentResponse.data));
        }
      }

      await reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
      await reduxDispatch(deepDiveWizardActions.updateWizardSubStep('SCORE_SUBMIT'));
    }

    if (wizardStep === 'REVIEW' && wizardSubStep === 'SCORE_SUBMIT') {
      reduxDispatch(deepDiveWizardActions.parseCategoryDetails(parsedCategoryValues));

      if (isHelpAvailable && failsQuestions?.length > 0) {
        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('INSTANT_HELP'));
        setPreviewInstantHelp(false);
      } else if (isHelpAvailable && failsQuestions?.length === 0) {
        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('INSTANT_HELP'));
        setPreviewInstantHelp(false);
      } else {
        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FOCUS_AREAS'));
      }
    }

    if (wizardStep === 'REVIEW' && wizardSubStep === 'FOCUS_AREAS') {
      reduxDispatch(deepDiveWizardActions.updateWizardStep('ACTIONS'));
      reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FINISH'));
    }
  };

  const handlePreviousCategory = () => {
    if (activeCategoryIndex > 0) {
      setActiveCategoryIndex((prevIndex) => prevIndex - 1);
      reduxDispatch(deepDiveWizardActions.updateWizardSubStep('QUESTIONS'));
      reduxDispatch(deepDiveWizardActions.updateWizardStep('SCORE'));
    }
  };

  function mapCategoriesToDataArray(): CreateStarCategoryInterface[] {
    return categoriesWithQuestions.map((category) => {
      const data =
        category.questions.length === 1
          ? Array(4).fill(category.questions[0].answeredScore ?? 7)
          : category.questions.map((q: Question) => q.answeredScore ?? 7);

      const questions = category.questions.map(
        (q: Question): CreateStarQuestionInterface => ({
          id: q.questionId,
          name: q.name,
          hint: q.questionInfo,
          score: q.answeredScore ?? 7,
          shareWith: q?.shareWith || 'mySelf',
          thoughtsText: '',
          scoreDifference: 0,
          color: q.color,
          specialWeighting: q.specialWeighting,
          isSelected: false,
        }),
      );

      return {
        id: category.id,
        name: category.name,
        color: category.color,
        darkColor: ColorPalette[category.color.toLocaleLowerCase()],
        lightColor: category.color,
        data,
        compareData: [],
        questions,
      };
    });
  }

  const parsedCategoryValues: CreateStarCategoryInterface[] = mapCategoriesToDataArray();

  function checkScoreFailsLimit() {
    const resultedQues: { question_id: number; limit: number }[] = [];

    questionLimits.forEach((limitItem) => {
      const questionItem = parsedCategoryValues.find((item) =>
        item.questions.some((q) => q.id === limitItem.question_id),
      );

      if (questionItem) {
        questionItem.questions.forEach((question) => {
          if (question.id === limitItem.question_id) {
            if (question.score <= limitItem.limit && question.score !== 0) {
              resultedQues.push({
                question_id: question.id,
                limit: limitItem.limit,
              });
            }
          }
        });
      }
    });

    const checkValidity = resultedQues?.length > 0;
    setFailsQuestions(resultedQues);
    reduxDispatch(updateHelpAvailability(checkValidity));
  }

  const logStarInstantHelp = async () => {
    const updatedQuestions = failsQuestions.map((failQue) => ({
      ...failQue,
      assessment_id: deepDiveWizard.assessmentDetails?.assessment_id ?? 0,
    }));

    const payload = {
      logType:
        isHelpAvailable && showSpeakUp
          ? LogTypes.SPEAKUP_DISPLAYED
          : LogTypes.INSTANT_HELP_DISPLAYED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    if (isHelpAvailable && showSpeakUp) {
      await logInstantHelpStarDetails(
        LogTypes.SPEAKUP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
      );

      await logInstantHelpStarDetails(
        LogTypes.INSTANT_HELP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    } else {
      await logInstantHelpStarDetails(
        payload.logType,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    }
  };

  const handleSKipToSetActionsInHelp = async (val: number) => {
    reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
    reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FOCUS_AREAS'));
  };

  return (
    <div className={styles.mainContainer}>
      {(() => {
        switch (wizardStep) {
          case DefaultDeepDiveValues.SCORING_STEP:
            return (
              <>
                {(() => {
                  if (wizardSubStep === DefaultDeepDiveValues.START_SUB_STEP) {
                    return <StarFirstStep />;
                  }

                  switch (wizardSubStep) {
                    case DefaultDeepDiveValues.QUESTIONS_SUB_STEP:
                      return (
                        <DeepDiveScoringStep
                          activeCategoryIndex={activeCategoryIndex}
                          categoriesWithQuestions={categoriesWithQuestions}
                          sliderValues={sliderValues}
                          handleSliderChange={handleSliderChange}
                          reduxDispatch={reduxDispatch}
                          deepDiveWizardActions={deepDiveWizardActions}
                          isIOS={isIOS}
                        />
                      );

                    default:
                      return (
                        <DeepDiveScoringStep
                          activeCategoryIndex={activeCategoryIndex}
                          categoriesWithQuestions={categoriesWithQuestions}
                          sliderValues={sliderValues}
                          handleSliderChange={handleSliderChange}
                          reduxDispatch={reduxDispatch}
                          deepDiveWizardActions={deepDiveWizardActions}
                          isIOS={isIOS}
                        />
                      );
                  }
                })()}
              </>
            );

          case DefaultDeepDiveValues.REVIEWING_STEP:
            return (
              <>
                {(() => {
                  switch (wizardSubStep) {
                    case DefaultDeepDiveValues.SCORES_SUBMIT_SUB_STEP:
                      return <ScoreSubmitStep />;

                    case DefaultDeepDiveValues.INSTANT_HELP_SUB_STEP:
                      return (
                        <HelpAndSpeakup
                          assesmentDataForHelp={assessmentDataForHelp}
                          handleAssessmentData={setAssessmentDataForHelp}
                          previewInstantHelp={previewInstantHelp}
                          handleInstantPreview={setPreviewInstantHelp}
                          skipStarStep={handleSKipToSetActionsInHelp}
                          handleSkipButton={setCustomButtonName}
                        />
                      );

                    case DefaultDeepDiveValues.FOCUS_AREAS_SUB_STEP:
                      return <SelectingFocusAreaStep isEditable />;

                    default:
                      return null;
                  }
                })()}
              </>
            );

          case DefaultDeepDiveValues.ACTIONS_STEP:
            return <ActionsForPlanStep isWizard />;

          default:
            return null;
        }
      })()}

      <DeepDiveWizardFooter
        handlePreviousCategory={handlePreviousCategory}
        handleNextCategory={handleNextCategory}
        activeCategoryIndex={activeCategoryIndex}
        activeCategoryId={categoriesWithQuestions[activeCategoryIndex]?.id || 0}
        isNextButtonEnabled={isNextButtonEnabled}
      />
    </div>
  );
};

interface FailsQuestionsInterface {
  question_id: number;
  limit: number;
}

interface SliderValues {
  [key: number]: {
    questionId: number;
    questionText: string;
    score: number;
  };
}

interface AssessmentData {
  assessmentId: number;
  questionnaireId: number;
}

export default DeepDiveQuestionContainerMobile;
